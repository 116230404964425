<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-img
                    align-center
                    justify-center
                    :src="logo"
                    alt="logo-recuperi"
                    class="mb-2"
                />
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Nova senha</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form
                            ref="form"
                            v-model="valid"
                            @keyup.native.enter="validarSubmit"
                        >
                            <v-text-field
                                ref="password"
                                prepend-icon="lock"
                                name="nova senha"
                                label="Nova senha"
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show1 = !show1"
                                @input="$refs.passwordConfirmation.validate()"
                                @keyup.native.tab="
                                    $refs.passwordConfirmation.focus()
                                "
                                hint="Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)"
                                data-cy="signinPasswordField"
                                v-model="password"
                                :rules="passwordRules"
                                required
                            ></v-text-field>
                            <v-text-field
                                ref="passwordConfirmation"
                                prepend-icon="lock"
                                name="Repita a nova senha"
                                label="Repita a nova senha"
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show1 = !show1"
                                data-cy="signinPasswordField"
                                v-model="passwordConfirmation"
                                :rules="passwordRulesConfirmation"
                                required
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            :disabled="!valid"
                            @click="submit"
                            data-cy="signinSubmitBtn"
                            :loading="load_troca_senha"
                        >
                            Registrar nova senha
                            <v-icon class="ml-1">chevron_right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="'red white--text'"
            :mensagemSnackbar="snackbarText"
            @fecharSnackbar="snackbar = false"
        />

        <v-dialog v-model="dialogMensagemFinal" max-width="400">
            <v-card>
                <v-card-text class="pt-5 mb-n10">{{
                    resposta_requisicao
                }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="dialogMensagemFinal = !dialogMensagemFinal"
                        to="/login"
                        >ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import esquecisenhaService from '@/services/esquecisenhaService';
import { validarSenha, validarStringSemEspaco } from '@/helpers/Utilitarios';
import Vue from 'vue';

export default {
    name: 'Login',
    data() {
        return {
            show1: false,
            logo: require('@/assets/Logo_azul_recuperi.png'),
            valid: false,
            dialogMensagemFinal: false,
            resposta_requisicao: '',
            load_troca_senha: false,
            password: '',
            passwordConfirmation: '',
            snackbar: false,
            snackbarText: '',
            queryToken: null,
            rules: {
                required: value => !!value || 'Required.',
                password: value => {
                    const pattern =
                        // eslint-disable-next-line no-useless-escape
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)'
                    );
                }
            },
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v =>
                    this.validarSenha(v) ||
                    'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Confirmar Senha é requerida',
                v => v == this.password || 'As senhas devem ser iguais',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ]
        };
    },
    mounted() {
        this.queryToken = this.$route.query.token;
    },
    methods: {
        validarSenha,
        validarStringSemEspaco,
        validarSubmit() {
            if (this.valid) {
                this.submit();
            } else {
                this.snackbarText =
                    'Preencha a senha de acordo com a orientação e repita ela no campo de confirmação';
                this.snackbar = true;
            }
        },
        submit() {
            this.snackbar = false;
            if (this.password === this.passwordConfirmation) {
                return new Promise((resolve, reject) => {
                    try {
                        let service = new esquecisenhaService(
                            Vue.http,
                            this.$store
                        );
                        this.load_troca_senha = true;
                        let password = this.password;
                        let queryToken = this.queryToken;
                        service.altera_senha(password, queryToken).then(
                            response => {
                                this.resposta_requisicao = response.body?.mensagem;
                                this.dialogMensagemFinal = true;
                                console.log(this.load_troca_senha);
                                this.load_troca_senha = false;
                            },
                            error => {
                                this.load_troca_senha = false;
                                this.snackbar = true;
                                this.snackbarText = error.body?.mensagem;
                            }
                        );
                    } catch (e) {
                        reject();
                        this.load_troca_senha = false;
                    }
                });
            } else {
                this.snackbarText = 'As senhas devem ser iguais';
                this.snackbar = true;
            }
        }
    }
};
</script>
